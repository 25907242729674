import React, { useEffect, useState } from "react";
import Header from "./header";
import { Modal, Spin } from "antd";
import { navigate } from "@helpers/navigator";
import { FaEye } from "react-icons/fa";
import { ROUTES } from "@views/routes/my_routes";
import { get_feedback_list_query } from "@services/redux/slices/dynamic_entity/graphql/feedback_graphql";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { retrieveItem } from "@helpers/storage";
import moment from "moment";
import { Button } from "@mui/material";

const PreviousFeedback = () => {
  const dispatch = useDispatch();
  const [feedback_open, set_feedback_open] = useState();
  const [feedback_description, set_feedback_description_open] = useState();
  const [deviceType, setDeviceType] = React.useState("desktop");

  const feedback_types = retrieveItem("feedback_types");
  const { items, loading } = useDynamicSelector("get_feedback_list");

  React.useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  const handleBack = () => {
    navigate(ROUTES.DASHBOARD);
  };
  const on_click = (des) => {
    set_feedback_description_open(des);
    set_feedback_open(true);
  };

  useEffect(() => {
    get_feedbacks();
  }, []);

  const get_feedbacks = () => {
    let key = [{ key: "get_feedback_list", loading: true }];
    let query = get_feedback_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables, "Q"));
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <Button
        size="small"
        variant="outlined"
        style={{ margin: "20px" }}
        onClick={handleBack}
      >
        Back
      </Button>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "23px",
            backgroundColor: "ButtonHighlight",
            width: "300px",
            padding: "5px",
          }}
        >
          Previous Feedback
        </div>
      </div>

      <br />
      <div>
        <div
          className="  text-start mt-5 mx-auto fw-bold"
          style={{ width: "90%" }}
        ></div>
        {deviceType === "mobile" ? (
          loading ? (
            <div className="text-center">
              <Spin size="large" />
            </div>
          ) : items?.length ? (
            items?.map((x, i) => (
              <table key={i} className="styled-table mx-auto">
                <tr>
                  <th style={{ border: "1px solid black" }}>Feedback Type</th>
                  <td style={{ border: "1px solid black" }}>
                    {feedback_types.find((y) => x.id === x.id)?.name}
                  </td>
                </tr>
                <tr>
                  <th style={{ border: "1px solid black" }}>Submission Date</th>
                  <td style={{ border: "1px solid black" }}>
                    {moment(x?.created_datetime).format("DD-MMM-YYYY")}
                  </td>
                </tr>
                <tr>
                  <th style={{ border: "1px solid black" }}>View</th>
                  <td
                    className="text-center"
                    style={{ border: "1px solid black" }}
                  >
                    <FaEye
                      style={{ cursor: "pointer" }}
                      onClick={() => on_click(x.description)}
                    />
                  </td>
                </tr>
              </table>
            ))
          ) : (
            <div className="text-center">No Feedback</div>
          )
        ) : (
          <table className="styled-table mx-auto">
            <thead>
              <tr>
                <th>Feedback Type</th>
                <th>Submission Date</th>
                <th className="text-center">View</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    <Spin size="large" />
                  </td>
                </tr>
              ) : items?.length ? (
                items?.map((x, i) => (
                  <tr key={i}>
                    <td>{feedback_types.find((y) => x.id === x.id)?.name}</td>
                    <td>{moment(x?.created_datetime).format("DD-MMM-YYYY")}</td>
                    <td className="text-center">
                      <FaEye
                        style={{ cursor: "pointer" }}
                        onClick={() => on_click(x.description)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No Feedbacks
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <Modal
          title="Feedback"
          open={feedback_open}
          onCancel={() => set_feedback_open(false)}
          footer={null}
        >
          <div
            style={{
              border: "1px solid black",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <p
              style={{
                fontSize: "17px",
              }}
            >
              {feedback_description}
            </p>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PreviousFeedback;
