import Login from "@views/pages/login/login";
import Redirector from "@views/pages/redirector";
// import AccessRightsPage from "@views/pages/access_rights";
import Outer from "@views/layouts/Outer";
import Dashboard from "@views/pages/dashboard";
import Home from "@views/pages/home";
import ApplyPass from "@views/pages/apply";
import ViewPass from "@views/pages/view_pass";
import PrivacyPolicy from "@views/pages/privacy";
import PreviousPasses from "@views/pages/previous_pass";
import PreviousFeedback from "@views/pages/previous_feedback";

export const ROUTES = {
  REDIRECTOR: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  // ACCESS_RIGHTS: "/access-rights",
  PROJECT_DETAILS: "/project-details/:project_id/:name",
  DRAW_IO: "/flow-chart",
  TIME_SHEET: "/timesheet",
  REIMBURSEMENT: "/reimbursement",
  REIMBURSEMENT_EMPLOYEE_DETAILS: "/reimbursement-details",
  EPASS_DOWNLOAD: "/epass-download",
  PASS: "/view-pass",
  HOME: "/home",
  APPLY_EPASS: "/apply-epass",
  PRIVACY_POLICY: "/privacy-policy",
  PREVIOUS_PASSES: "/previous-passes",
  PREVIOUS_FEEDBACK:"/previous-feedbacks"
};

const myRoutes = [
  {
    path: ROUTES.REDIRECTOR,
    exact: true,
    key: "redirector",
    component: Redirector,
    authenticate: false,
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    exact: true,
    key: "privacy_policy",
    component: PrivacyPolicy,
    authenticate: false,
  },

  {
    path: `${ROUTES.PASS}/:id`,
    exact: true,
    key: "view_pass",
    component: ViewPass,
    authenticate: false,
  },

  // {
  //   path: ROUTES.ACCESS_RIGHTS,
  //   key: "Access Rights",
  //   authenticate: false,
  //   component: AccessRightsPage,
  // },
  {
    key: "user_login",
    authenticate: false,
    component: Outer,
    children: [
      {
        path: ROUTES.HOME,
        exact: true,
        key: "pass",
        component: Home,
        authenticate: false,
      },
      {
        path: `${ROUTES.LOGIN}/:type`,
        key: "login",
        component: Login,
        authenticate: false,
      },
    ],
  },

  {
    path: `${ROUTES.APPLY_EPASS}/:id`,
    key: "apply-pass",
    component: ApplyPass,
    authenticate: true,
  },
  {
    path: ROUTES.DASHBOARD,
    key: "dashboard",
    component: Dashboard,
    authenticate: true,
  },
  {
    path: ROUTES.PREVIOUS_FEEDBACK,
    key: "previous-feedbacks",
    component: PreviousFeedback,
    authenticate: true,
  },
  {
    path: ROUTES.PREVIOUS_PASSES,
    key: "previous-passes",
    component: PreviousPasses,
    authenticate: true,
  },
 
];
export default myRoutes;
