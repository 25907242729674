import { gql } from "@apollo/client";

export const get_otp_query = gql`
  query get_otp($data: create_otp_input) {
    get_otp: get_otp(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const login_query = gql`
  query login_user($data: user_login_input) {
    login_user: login_user(data: $data) {
      data
      error {
        status_code
        message
      }
    }
  }
`;
