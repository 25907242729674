import { Skeleton } from "@mui/material";
import React from "react";
const MuiSkeleton = (props) => {
  const {
    variant_shape,
    width,
    height,
    animation_type,
    border_radius,
    marginTop,
  } = props;
  return (
    <Skeleton
      variant={variant_shape || "rectangle"}
      width={width || "100%"}
      height={height || 60}
      animation={animation_type || "wave"}
      style={{
        borderRadius: border_radius || 0,
        marginTop: marginTop || 10,
      }}
    />
  );
};
export default MuiSkeleton;
