import React, { useEffect } from "react";

import {
  dynamicClear,
  dynamicRequest,
  submit_feedback_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { retrieveItem } from "@helpers/storage";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import toast from "react-hot-toast";

function Feedback(props) {
  const { handle_primary_cancel } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const feedback_types = retrieveItem("feedback_types");
  const { loading, status, error } = useDynamicSelector("submit_feedback");
  useEffect(() => {
    if (status === "success") {
      toast.success(
        "Feedback submitted successfully, Thanks for your valuable feedback!"
      );
      form.resetFields();
      dispatch(dynamicClear("submit_feedback"));
      handle_primary_cancel();
    } else if (error?.message) {
      toast.error(error?.message);
      dispatch(dynamicClear("submit_feedback"));
    }
  }, [status]);
  useEffect(() => {
    form.resetFields();
  }, []);
  const on_finish = (values) => {
    let submit_feedback_key = [{ key: "submit_feedback", loading: true }];
    let submit_feedback_variables = {
      data: { ...values },
    };
    dispatch(
      dynamicRequest(
        submit_feedback_key,
        submit_feedback_query,
        submit_feedback_variables,
        "M"
      )
    );
  };
  const handle_previous_feedback = () => {
    navigate(ROUTES.PREVIOUS_FEEDBACK);
  };
  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={on_finish}
        // onValuesChange={onChange}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="feedback_type_id"
              label="Feedback Type"
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Feedback Type!",
                },
              ]}
            >
              <Select size="large">
                {feedback_types?.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please Enter Description!",
                },
              ]}
            >
              <Input.TextArea maxLength={200} showCount={true} />
            </Form.Item>
          </Col>
        </Row>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#346732" }}
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
      <div
        className="feedback-text text-center my-2"
        onClick={handle_previous_feedback}
      >
        Previous Feedback
      </div>
    </>
  );
}

export default Feedback;
