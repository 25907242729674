import { gql } from "@apollo/client";

export const get_application_list_query = gql`
  query get_application_list {
    get_application_list: get_application_list {
      items {
        id
        valid_till
        applied_on
        destination_id
        vehicle_number
        application_status
        destination {
          name
        }
        valid_from
        from_country {
          name
        }
        from_state {
          name
        }
        from_district {
          name
        }
        pass_number
        applicant {
          name
        }
        total_passengers
        vehicle_type {
          name
        }
        vehicle_fuel_type
        name
      }
    }
  }
`;

export const get_one_application_query = gql`
  query get_application($id: String!) {
    get_application: get_application(id: $id) {
      id
      pass_number
      from_address_1
      from_address_2
      from_pincode
      total_passengers
      valid_from
      valid_till
      applied_on
      vehicle_number
      approved_on
      qr_data
      vehicle_fuel_type
      destination_name
      destination_type
      destination_address
      vehicle_manufactured_year
      destination {
        name
        code
      }
      vehicle_type {
        name
      }
      approved_by {
        name
      }
      name
      reason {
        name
        code
      }
      from_state {
        name
        code
      }
      from_country {
        name
      }
      from_district {
        name
        code
      }
      applicant {
        name
        mobile
        email
      }
    }
  }
`;

export const submit_application_query = gql`
  mutation submit_application($data: create_application_input) {
    submit_application: submit_application(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const submit_local_resident_application = gql`
  mutation submit_local_resident_application(
    $data: local_resident_application_input
  ) {
    submit_local_resident_application(data: $data) {
      id
      pass_number
      from_address_1
      from_address_2
      from_pincode
      total_passengers
      valid_from
      valid_till
      applied_on
      vehicle_number
      approved_on
      qr_data
      vehicle_fuel_type
      vehicle_manufactured_year
      destination {
        name
        code
      }
      vehicle_type {
        name
      }
      approved_by {
        name
      }
      name
      reason {
        name
        code
      }
      from_state {
        name
        code
      }
      from_country {
        name
      }
      from_district {
        name
        code
      }
      applicant {
        name
        mobile
      }
      error {
        message
      }
    }
  }
`;

export const cancel_epass_mutation = gql`
  mutation cancel_application($data: cancel_application_input) {
    cancel_application(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
