import React from "react";
import useStyles from "./appStyles-jss";

function Outer(props) {
  const { classes, cx } = useStyles();
  const { children } = props;
  return (
    <div className={cx(classes.appFrameOuter, classes.gradientBg)}>
      <main className={classes.outerContent} id="mainContent">
        <div className={classes.petal} />
        {children}
      </main>
    </div>
  );
}

export default Outer;
