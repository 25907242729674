import React, { useEffect, useState } from "react";
import file from "@assets/images/file.png";
import cancel_file from "@assets/images/cancel_epass.png";
import { get_application_list_query } from "@services/redux/slices/dynamic_entity/graphql/application_graphql";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Divider, Modal, Spin } from "antd";
import Pass from "./pass";
import { destination_id } from "@helpers/constants";
import Header from "./header";
import { Button } from "@mui/material";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import CancelPass from "@views/components/download_epass/cancel_epass/cancel_epass_content";
import toast from "react-hot-toast";
import { startCase } from "lodash";
const PreviousPasses = () => {
  const dispatch = useDispatch();
  const [model_open, set_model_open] = useState(false);
  const [is_cancel_modal_open, set_is_cancel_modal_open] = useState(false);
  const [pass_data, set_pass_data] = useState("");
  const [application_details, set_application_details] = useState();
  const [deviceType, setDeviceType] = React.useState("desktop");

  React.useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);
  const { items: applications, loading } = useDynamicSelector(
    "get_application_list"
  );

  const {
    status: cancel_application_status,
    loading: cancel_application_loading,
    error: cancel_application_error,
  } = useDynamicSelector("cancel_application");

  useEffect(() => {
    get_applications();
  }, []);

  useEffect(() => {
    if (cancel_application_status === "success") {
      toast.success("Your ePass has been canceled");
      get_applications();
      handle_close_cancel_pass_modal();
      dispatch(dynamicClear("cancel_application"));
    } else if (cancel_application_error) {
      toast.error(cancel_application_error?.message);
      dispatch(dynamicClear("cancel_application"));
    }
  }, [cancel_application_status, cancel_application_error]);

  const get_applications = () => {
    let key = [{ key: "get_application_list", loading: true }];
    let query = get_application_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables, "Q"));
  };

  const handle_close_cancel_pass_modal = () => {
    set_is_cancel_modal_open(false);
  };
  const handle_open_cancel_pass_modal = (details) => {
    set_is_cancel_modal_open(true);
    set_application_details(details);
  };

  const handle_download = (data) => {
    set_model_open(true);
    set_pass_data(data);
  };
  const handle_cancel = () => {
    set_model_open(false);
  };
  const handleBack = () => {
    navigate(ROUTES.DASHBOARD);
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <Button
        size="small"
        variant="outlined"
        style={{ margin: "20px" }}
        onClick={handleBack}
      >
        Back
      </Button>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "23px",
            backgroundColor: "ButtonHighlight",
            width: "300px",
            padding: "5px",
          }}
        >
          Previous Passes
        </div>
      </div>

      <br />
      <div>
        {deviceType === "mobile" ? (
          loading ? (
            <div className="text-center">
              <Spin size="large" />
            </div>
          ) : applications?.length ? (
            applications?.map((x, i) => (
              <table key={i} className="styled-table mx-auto">
                <tr>
                  <th style={{ border: "1px solid black", width: "50%" }}>
                    Vehicle Number
                  </th>
                  <td style={{ border: "1px solid black" }}>
                    {x.vehicle_number}
                  </td>
                </tr>
                <tr>
                  <th style={{ border: "1px solid black" }}>Destination</th>
                  <td style={{ border: "1px solid black" }}>
                    {destination_id[x.destination_id]}
                  </td>
                </tr>
                <tr>
                  <th style={{ border: "1px solid black" }}>Applied On</th>
                  <td style={{ border: "1px solid black" }}>
                    {moment(x?.applied_on).format("DD-MMM-YYYY")}
                  </td>
                </tr>
                <tr>
                  <th style={{ border: "1px solid black" }}>Validity</th>
                  <td style={{ border: "1px solid black" }}>
                    {moment(x?.valid_till).format("DD-MMM-YYYY")}
                  </td>
                </tr>
                <tr>
                  <th style={{ border: "1px solid black" }}>Status</th>
                  <td style={{ border: "1px solid black" }}>
                    {startCase(x.application_status)}
                  </td>
                </tr>
                {x?.application_status === "approved" && (
                  <tr>
                    <th style={{ border: "1px solid black" }}>Download Pass</th>
                    <td
                      className="text-center"
                      style={{ border: "1px solid black" }}
                    >
                      <div className="row">
                        <div className="col-6 text-end">
                          {/* <img
                            src={file}
                            height={20}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handle_download(x.id);
                            }}
                          ></img> */}
                          <span
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: "blue",
                              fontSize: 12,
                            }}
                            onClick={() => {
                              handle_download(x.id);
                            }}
                          >
                            Download
                          </span>
                        </div>
                        <div className="col-6 text-start">
                          {/* <img
                            src={cancel_file}
                            height={20}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handle_open_cancel_pass_modal(x);
                            }}
                          ></img> */}
                          <span
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              color: "red",
                              fontSize: 12,
                            }}
                            onClick={() => {
                              handle_open_cancel_pass_modal(x);
                            }}
                          >
                            Cancel
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </table>
            ))
          ) : (
            <div className="text-center">No Passes</div>
          )
        ) : (
          <table className="styled-table mx-auto">
            <thead>
              <tr>
                <th>Vehicle Number</th>
                <th>Destination</th>
                <th>Applied On</th>
                <th>Validity</th>
                <th>Status</th>
                <th className="text-center">Download Pass</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5} className="text-center">
                    <Spin size="large" />
                  </td>
                </tr>
              ) : applications?.length ? (
                applications?.map((x, i) => (
                  <tr key={i}>
                    <td>{x.vehicle_number}</td>
                    <td>{destination_id[x.destination_id]}</td>
                    <td>{moment(x?.applied_on).format("DD-MMM-YYYY")}</td>
                    <td>{moment(x?.valid_till).format("DD-MMM-YYYY")}</td>
                    <td>{startCase(x?.application_status)}</td>
                    <td className="text-center">
                      {x?.application_status === "approved" && (
                        <div className="row">
                          <div className="col-6 text-end">
                            {/* <img
                              src={file}
                              height={20}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handle_download(x.id);
                              }}
                            ></img> */}
                            <span
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "blue",
                                fontSize: 14,
                              }}
                              onClick={() => {
                                handle_download(x.id);
                              }}
                            >
                              Download
                            </span>
                          </div>
                          <div className="col-6 text-start">
                            {/* <img
                              src={cancel_file}
                              height={20}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handle_open_cancel_pass_modal(x);
                              }}
                            ></img> */}
                            <span
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: "red",
                                fontSize: 14,
                              }}
                              onClick={() => {
                                handle_open_cancel_pass_modal(x);
                              }}
                            >
                              Cancel
                            </span>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No Passes
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        <Modal
          title={"Download Your Pass"}
          open={model_open}
          width={1000}
          // onOk={handle_ok}
          onCancel={handle_cancel}
          footer={null}
        >
          <Pass id={pass_data} />
        </Modal>
      </div>
      <Modal
        title={"Cancel ePass"}
        // title={
        //   <div
        //     style={{
        //       fontWeight: "bold",
        //       display: "block",
        //     }}
        //   >
        //     Cancel ePass
        //     <br />
        //     {application_details?.destination?.name} (
        //     {moment(application_details?.valid_from).format("DD-MMM-YYYY")}-
        //     {moment(application_details?.valid_till).format("DD-MMM-YYYY")})
        //   </div>
        // }
        open={is_cancel_modal_open}
        width={500}
        // onOk={handle_ok}
        destroyOnClose={true}
        onCancel={() => {
          handle_close_cancel_pass_modal();
        }}
        footer={null}
      >
        <Divider />

        <CancelPass application_details={application_details} />
      </Modal>
    </div>
  );
};
export default PreviousPasses;
