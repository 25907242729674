import React, { useState } from "react";
import "./card.css"; // Import your CSS file

function Card({ imageUrl, color, text, icon, onClick }) {
  return (
    <div className="card transparent" onClick={onClick}>
      <img
        className="card-img"
        src={imageUrl}
        alt="Card Image"
        width={280}
        height={150}
      />
      <div className="overlay" style={{ backgroundColor: color }}>
        <div className="row">
          {icon ? (
            <div className="icon">
              <img src={icon} alt="icon" height={40} />
            </div>
          ) : (
            ""
          )}
          <div className="text-center text-bold">{text}</div>
        </div>
      </div>
    </div>
  );
}

export default Card;
