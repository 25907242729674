import { gql } from "@apollo/client";

export const get_feedback_list_query = gql`
  query get_feedback_list {
    get_feedback_list: get_feedback_list {
      items{
        id
        created_datetime
        description
      }
    }
  }
`;

export const submit_feedback_query = gql`
  mutation create_feedback($data: create_feedback_input) {
    submit_feedback: create_feedback(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;
