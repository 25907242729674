import { gql } from "@apollo/client";
import { showToast } from "@helpers/toast";
import { getUUID } from "@helpers/uuid";
import { UploadOutlined } from "@mui/icons-material";
import { QueryRequest } from "@services/apollo/api_service";
import { Button, Form, Upload } from "antd";
import axios from "axios";
import { useState } from "react";

export const fileQuery = gql`
  query get_signed_url($data: signed_url_input) {
    get_signed_url(data: $data) {
      url
      status
    }
  }
`;
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

let fileUploadresponse;

const File = (props) => {
  const [fileList, setFileList] = useState({});
  const [headers, setHeaders] = useState();
  const handleBeforeUpload = async ({ name, type, size }) => {
    const maxSize = 1 * 1024 * 1024; // 2 MB in bytes

    if (size > maxSize) {
      showToast({
        type: "error",
        message: "File size exceeds the maximum limit of 1 MB",
      });
      return Upload.LIST_IGNORE; // Prevents the file from being added to the file list
    }
    let file_type = name.split(".")?.[name.split(".").length - 1];
    let fileupload = {
      data: {
        file_name: name,
      },
    };

    let format_list = props?.allowFileFormats?.filter(
      (format) => format === file_type
    );
    if (format_list?.length !== 0) {
      fileUploadresponse = await QueryRequest(fileQuery, fileupload);
    } else {
      showToast({
        type: "error",
        message: "Document is not in valid file format",
      });
      fileUploadresponse = {
        error_message: `Allowed file formats ${props?.allowFileFormats.map(
          (format) => format
        )}`,
      };
      setFileList([]);
    }
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (
      file.status === "done" &&
      fileUploadresponse?.data?.get_signed_url?.status === "success"
    ) {
      const newFile = {
        ...file,
        name: getUUID(),
        url: fileUploadresponse?.data?.get_signed_url.url,
      };
      setFileList([newFile]);
      showToast({
        type: "success",
        message: "Document uploaded successfully",
      });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    if (!fileUploadresponse?.error_message) {
      axios
        .put(fileUploadresponse?.data?.get_signed_url.url, file, {
          headers: { "Content-Type": file?.type },
        })
        .then(async (res) => {
          if (res.status.toString() === "200") {
            file.url =
              fileUploadresponse?.data?.get_signed_url.url.split("?")[0];
            onSuccess(null, file);
          } else {
            onError(err, err, file);
          }
        })
        .catch((err) => {
          onError(err, err, file);
        });
    } else {
      onError(null, null, fileUploadresponse?.error_message);
    }
  };
  const action = fileUploadresponse?.data?.get_signed_url;
  return (
    <>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={props.label}
        name={props.field}
        disabled={props.disabled}
        rules={props.rules}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
      >
        <Upload
          maxCount={props.maxCount ? props.maxCount : 1}
          action={action}
          headers={headers}
          fileList={fileList}
          disabled={props.disabled}
          // showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
          customRequest={(e) => handleUpload(e)}
          beforeUpload={(args) => handleBeforeUpload(args)}
          onChange={(e) => handleFileChanged(e)}
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
          <h6 className="allowed-file-formats">
            {"Allowed File Formats - PNG, JPEG"}
          </h6>
          <h6 className="maximum-file-size">{"(File size maximum 1 MB)"}</h6>
        </Upload>
      </Form.Item>

      {/* {props.allowFileFormats && (
        <div>
          <h6>
            Allowed file formats{" "}
            {`${props?.allowFileFormats?.map((s) => s)}`.replace(/,/g, ", ")}
          </h6>
        </div>
      )} */}
    </>
  );
};
let imageFileUploadresponse;
const ImageFile = (props) => {
  const [fileList, setFileList] = useState({});
  const [headers, setHeaders] = useState();
  const uploadButton = (
    <div>
      <img
        width={"100px"}
        style={{
          objectFit: "cover",
        }}
        src={
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAgVBMVEXw8PABFicAAAD39/cACyD6+fpJUVgAABiLjpHS0tP8/PzOzs8AABXs7e0ADyIAAAacnqAtOUMAABEcKjamp6pYX2UdJTCtsLN8gIQAABvk5eYAAA3Bw8UpND4gLTkyPUZqbnOWmZyGiYx6foMXHywYHyxrcHW4u73a3N1ASFEAECD3KgboAAACYElEQVR4nO3c63KaQBiAYWAxKBVijEbRYAxJc+j9X2DBnhbcTpFhT/Z9fjIfie+M4mGBIAAAAAAAAAAAAAAAAAAAAAAAAAAAAABwxcTuiy6ZsB138hjpM7Md10jLONSlWE9s59XS+1xbYXzjTGFyO77CpcL5KsjGFkwLhwqXq/EPemKaUGgIhUNRaA6FQ1FozjUWirTlUBdu0lHIPRYLxba8kzzVn5Dzw90YyqP0VLBXKKoob2m+BOTj2B//FNkrnNzo+z6YP6SuFMbz8dV/Nr93pTBeL8Z3LBwqTKapGFu6WjpVqOH9j0JjKByKQnMoHIpCcy4rFGnvST8LxXNZ9R31slBsojh67jnrZ+FLUX9K7/coPS3cUijNUmgMhTIKW7MUGkOhjMLWLIXGUCijsDXrcWH678HAk8LztYgfhd3NyoftQ6GoXh86XptVwe7Gr2/KvT0ofI/OFnVD5QJxtFDs7kGhWOx7LvUmWz8Lgyy53Xc0C+J5d+M++lDs7UNhkG261nGYf753t+58fR2eH0z/cixV7+tFYdf/8o5P4c9ZCo2hUEZha5ZCYyiUUdiapdAYCmUUtmY7hRM/CqtlWBwvKfz4faX67MXWNaQXnTG0+xYpf7JQaArDXLpS3dp1wBee19b7rgGnwg4vCntTFc6Vv6zqprUwb99SodyN/m960Hr25aG9xmHnthimzi+1h8KhKDSHwqHcKiwqDfcyWc0dKgwLDXdMSkKXCjVxpHBdaCuMSxcKg5mGZ+gvj7bjTkQ200W5OAwAAAAAAAAAAAAAAAAAAAAAAAAAAADganwH5zd6LobMFeIAAAAASUVORK5CYII="
        }
      />
    </div>
  );
  const handleBeforeUpload = async ({ name, type }) => {
    let extension = name.split(".")[1];
    let fileupload = {
      type: "photo",
      fileName: getUUID() + "." + extension,
    };
    let file_type = name.split(".");
    let format_list = props?.allowFileFormats?.filter(
      (format) => format === file_type[1]
    );
    if (format_list?.length !== 0) {
      imageFileUploadresponse = await QueryRequest(fileQuery, fileupload);
    } else {
      showToast({
        type: "error",
        message: "Document is not in valid file format",
      });
    }
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (
      file.status === "done" &&
      imageFileUploadresponse?.data?.getUploadUrl?.status === "Success"
    ) {
      const newFile = {
        ...file,
        name: getUUID(),
        url: imageFileUploadresponse?.data?.get_signed_url.url,
      };
      setFileList([newFile]);
      showToast({
        type: "success",
        message: "Document uploaded successfully",
      });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(imageFileUploadresponse?.data?.get_signed_url.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url =
            imageFileUploadresponse?.data?.get_signed_url.url.split("?")[0];
          onSuccess(null, file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const action = imageFileUploadresponse?.data?.getUploadUrl;
  return (
    <>
      <Form.Item
        label={props.label}
        name={props.field}
        rules={props.rules}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra=""
      >
        <Upload
          maxCount={props.maxCount ? props.maxCount : 1}
          action={action}
          headers={headers}
          fileList={fileList}
          disabled={props.isdisabled}
          listType="picture-card"
          showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
          customRequest={(e) => handleUpload(e)}
          beforeUpload={(args) => handleBeforeUpload(args)}
          onChange={(e) => handleFileChanged(e)}
        >
          {/* {fileList?.length >= 1 ? null : uploadButton} */}
          {fileList?.length > 0 ? null : (
            <Button
              style={{
                width: "100%",
                height: "100%",
              }}
              icon={<UploadOutlined />}
            >
              Upload
            </Button>
          )}
        </Upload>
      </Form.Item>
      {/* {props?.allowFileFormats && (
        <div style={{ marginTop: "20px", marginBottom: "10px" }}>
          <h6 style={{ color: "red" }}>
            Allowed file formats{" "}
            {`${props?.allowFileFormats?.map((s) => s)}`?.replace(/,/g, ", ")}
          </h6>
        </div>
      )} */}
    </>
  );
};

const AntdDynamicForm = (props) => {
  const { children } = props;
  let _form = props.form;
  if (!_form) {
    const [form] = Form.useForm();
    _form = form;
  }
  const [initialValues, setInitialValues] = useState(props.initialValues);
  useEffect(() => {
    _form.setFieldsValue(props.initialValues);
    setInitialValues(props.initialValues);
  }, [_form, props.initialValues]);
  return (
    <Form
      id={props.name}
      disabled={props.disabled}
      form={props.form || _form}
      name={props.id}
      layout={props?.layout ? props?.layout : "vertical"}
      onFinish={props.onSubmit}
      onValuesChange={props.onValueChange || props.onValuesChange}
      initialValues={initialValues}
      onChange={props.onChange}
      action=""
    >
      {children}
    </Form>
  );
};
AntdDynamicForm.File = File;
AntdDynamicForm.ImageFile = ImageFile;
export default AntdDynamicForm;
