import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import './styles/layout/base.scss';
import "./views/components/styles/layout/base.scss";
import "react-18-image-lightbox/style.css";
import "sanitize.css/sanitize.css";

import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import { store } from "@services/redux/store";
import App from "./App";
import "./i18n";
import { Toaster } from "react-hot-toast";
import { blue, green, purple } from "@mui/material/colors";
import applicationTheme from "@views/components/styles/theme/applicationTheme";

// Create a Material-UI theme

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: green[200],
//     },
//     secondary: {
//       main: blue[500],
//     },

//   },

//   breakpoints: {
//     values: {
//       xs: 320,
//       sm: 480,
//       md: 768,
//       lg: 1024,
//       xl: 1440,
//       "2xl": 2560,
//       "3xl": 3840,
//       mobile: true,
//       tablet: true,
//       laptop: true,
//       desktop: true,
//     },
//   },
// });

const muiTheme = createTheme(applicationTheme("skyBlueTheme", "light", "ltr"));

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={muiTheme}>
      <IconContext.Provider value={{ className: "global-class-name" }}>
        <App />
        <ToastContainer newestOnTop pauseOnFocusLoss />
        <Toaster position="top-center" reverseOrder={false} />
      </IconContext.Provider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
