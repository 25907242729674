import ApplyEPass from "@views/components/download_epass/epass_apply_form";
import React, { useRef } from "react";
import Header from "./header";
import { Button } from "@mui/material";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";

const ApplyPass = () => {
  const handleBack = () => {
    navigate(ROUTES.DASHBOARD);
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />

      <Button
        size="small"
        variant="outlined"
        style={{ margin: "20px" }}
        onClick={handleBack}
      >
        Back
      </Button>
      <div className="w-75 mx-auto">
        <ApplyEPass />
      </div>
    </div>
  );
};

export default ApplyPass;
