import React, { useEffect, useRef, useState } from "react";
import useStyles from "./user-jss";
import { Form, Input } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  userLogin,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { storeItem } from "@helpers/storage";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import MuiInput from "@views/components/system/Forms/form_elements/mui_input";
import SubmitButton from "@views/components/system/Forms/form_elements/submit_button";
import { MdOutlineRefresh } from "react-icons/md";
import { decrypt_data, encrypt_data } from "@helpers/crypto";
import { navigate } from "@helpers/navigator";
import {
  get_captcha,
  get_otp_query,
  login_query,
} from "@services/redux/slices/dynamic_entity/graphql";
import { TextField } from "@mui/material";

function LoginForm(props) {
  const { classes, cx } = useStyles();
  const { is_india } = props;

  const {
    captcha,
    id,
    loading: captcha_loading,
    error: captcha_error,
  } = useDynamicSelector("request_captcha");

  const {
    status: otp_status,
    error: otp_error,
    loading: otp_loading,
  } = useDynamicSelector("get_otp");
  const dispatch = useDispatch();

  const [has_otp, set_has_otp] = useState(false);
  const canvasRef = useRef();

  const {
    error,
    data,
    loading: login_loading,
  } = useDynamicSelector("login_user");
  useEffect(() => {
    get_captcha_request();
    dynamicClear("login_user");
    dynamicClear("get_otp");
    set_has_otp(false);
  }, []);
  useEffect(() => {
    if (captcha) {
      createCaptcha();
    }
  }, [captcha]);

  useEffect(() => {
    if (otp_status === "success") {
      set_has_otp(true);
      dispatch(dynamicClear("get_otp"));
    } else if (otp_error?.message) {
      toast.error(otp_error.message);
      dispatch(dynamicClear("get_otp"));
    }
  }, [otp_status]);

  useEffect(() => {
    if (data) {
      let decrypted_data = decrypt_data(data);
      storeItem("session_id", decrypted_data.session_id);
      storeItem("foreign", JSON.stringify(!is_india));
      navigate("/dashboard");
      dispatch(dynamicClear("login_user"));
    }

    if (error) {
      toast.error(error.message);
      dispatch(dynamicClear("login_user"));
    }
    // if (token) {
    //   if (force_password_change) {
    //     navigate(ROUTES.CHANGE_PASSWORD);
    //   } else {
    //     navigate(ROUTES.DASHBOARD);
    //   }
    // }
  }, [data, error]);

  const get_captcha_request = () => {
    let key = [{ key: "request_captcha", loading: true }];
    let query = get_captcha;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables, "Q"));
  };

  const handle_submit = (values) => {
    if (values.otp) {
      let user_login_key = [{ key: "login_user", loading: true }];
      let user_login_query = login_query;
      let user_login_variables = {
        data: { mobile: values.mobile, email: values.email, otp: values.otp },
      };
      dispatch(
        dynamicRequest(user_login_key, user_login_query, user_login_variables)
      );
    } else {
      let get_otp_key = [{ key: "get_otp", loading: true }];
      let get_otp_variables = {
        data: {
          captcha_id: id,
          mobile: values.mobile,
          email: values.email,
          captcha: encrypt_data(values.captcha),
        },
      };

      dispatch(dynamicRequest(get_otp_key, get_otp_query, get_otp_variables));
    }
  };

  const go_to_change_password = () => {
    dispatch(dynamicClear("login"));
    navigate("/change-password");
  };

  const createCaptcha = () => {
    let decryptCaptcha = decrypt_data(captcha);
    // let decryptCaptcha = "123456";
    if (canvasRef && canvasRef.current) {
      let canvas = canvasRef.current;
      canvas.width = 150;
      canvas.height = 100;
      let ctx = canvas.getContext("2d");
      ctx.font = "bold 20px Arial";
      // ctx.fillStyle = "#13304e";
      ctx.fillText(decryptCaptcha, 10, 20);
      ctx.textAlign = "center";
      ctx.alignItems = "center";
      ctx.justifyContent = "center";
    }
  };

  const handle_click = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <section className={classes.pageFormSideWrap}>
      <Form onFinish={handle_submit}>
        <>
          {!is_india && (
            <Form.Item
              name={"email"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "The input is not a valid email!",
                },
              ]}
              className="no_margin_form_input"
            >
              <TextField
                id="filled-required"
                variant="standard"
                label={"Email *"}
                className={classes.field}
                type={"text"}
              />
            </Form.Item>
          )}
          {is_india && (
            // <MuiInput
            //   field={"mobile"}
            //   label={"மொபையில் எண் / Mobile Number"}
            //   message={"Mobile number is required"}
            //   is_required={true}
            //   class_name={classes.field}
            // />
            <Form.Item
              name={"mobile"}
              rules={[
                {
                  required: true,
                  message: "Please input your mobile number!",
                },

                {
                  pattern: /^[6-9]\d{9}$/,
                  message: "Please enter a valid Indian mobile number!",
                },
              ]}
              className="no_margin_form_input"
            >
              <TextField
                id="filled-required"
                variant="standard"
                label={"Mobile Number *"}
                className={classes.field}
                // type={"number"}
                inputProps={{ maxLength: 10, inputMode: "numeric" }}
              />
            </Form.Item>
          )}
          <div className="row mt-2 my-auto">
            <div
              className="col-3"
              style={{
                borderRadius: "10px",
                // backgroundColor: "#aec4d9",
                alignItems: "center",
                height: "40px",
                marginTop: "25px",
              }}
            >
              <canvas
                ref={canvasRef}
                style={{
                  objectFit: "cover",
                  paddingTop: "6px",
                  display: captcha_loading ? "none" : "block",
                }}
              />
              {captcha_loading && (
                <div class="leap-frog">
                  <div class="leap-frog__dot"></div>
                  <div class="leap-frog__dot"></div>
                  <div class="leap-frog__dot"></div>
                </div>
              )}
            </div>
            <div
              className="col-1"
              style={{
                marginTop: "30px",
              }}
            >
              <MdOutlineRefresh
                onClick={get_captcha_request}
                size={20}
                style={{ cursor: "pointer", color: "#14304e" }}
              />
            </div>
            <div className="col-8">
              <Form.Item
                className="form-captcha"
                name={"captcha"}
                rules={[
                  {
                    required: true,
                    message: "Captcha is required",
                  },
                ]}
              >
                <MuiInput
                  field={"captcha"}
                  label={"Captcha"}
                  is_required={true}
                  class_name={classes.field}
                />
              </Form.Item>
            </div>
          </div>
        </>

        {has_otp && (
          <MuiInput
            field={"otp"}
            label={"Enter OTP"}
            message={"OTP number is required"}
            is_required={true}
            class_name={classes.field}
          />
        )}

        <SubmitButton
          label={!has_otp ? "GET OTP" : "உள்நுழைய / Login"}
          show_icon={true}
          class_name={classes.btnArea}
          icon_class_name={cx(classes.rightIcon, classes.iconSmall)}
          loading={!has_otp ? otp_loading : login_loading}
          // handle_click={handle_click}
        />
      </Form>
    </section>
  );
}

export default LoginForm;
