import CryptoJS from "crypto-js";
const SECRET_KEY = "4Y1oO0ElwMgib9bwVyglYxqhdpWBFpxw";
export function decrypt_data(data) {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export function encrypt_data(data) {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY);
  return encrypted.toString();
}
