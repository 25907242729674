import { gql } from "@apollo/client";

export const get_captcha = gql`
  query request_captcha {
    request_captcha: request_captcha {
      id
      captcha
      error {
        status_code
        message
      }
    }
  }
`;
