import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Loading from "@mui/material/LinearProgress";

import appTheme from "./applicationTheme";

const useStyles = makeStyles()(() => ({
  root: {
    width: "100%",
    minHeight: "100%",
    marginTop: 0,
    zIndex: 1,
  },
  loading: {
    zIndex: "10 !important",
    position: "fixed !important",
    top: 0,
    left: 0,
    width: "100%",
    opacity: 1,
    transition: "opacity .5s ease",
  },
  loadingWrap: {
    background: "none !important",
  },
  bar: {
    background: "rgba(255, 255, 255, 0.7) !important",
  },
  hide: {
    opacity: 0,
  },
}));

const isBrowser = typeof document !== "undefined";
let insertionPoint;

if (isBrowser) {
  const emotionInsertionPoint = document.querySelector(
    'meta[name="emotion-insertion-point"]'
  );
  insertionPoint = emotionInsertionPoint ?? undefined;
}

const cacheLTR = createCache({
  key: "mui-style-ltr",
  insertionPoint,
});

export const ThemeContext = React.createContext(undefined);

function ThemeWrapper(props) {
  const { classes } = useStyles();
  const [progress, setProgress] = useState(0);
  const {
    children,
    color,
    mode,
    palette,
    gradient,
    decoration,
    bgPosition,
    layout,
    direction,
    changeMode,
    changeTheme,
    changeGradient,
    changeDecoration,
    changeBgPosition,
    changeLayout,
    changeDirection,
  } = props;

  const [theme, setTheme] = useState(appTheme(color, mode, direction));
  const [paletteState, setPalette] = useState([]);

  useEffect(() => {
    setPalette(palette);
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
        }
        const diff = Math.random() * 40;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const muiTheme = createTheme(theme);

  return (
    <CacheProvider value={cacheLTR}>
      <ThemeProvider theme={muiTheme}>
        <div className={classes.root}>
          <Loading
            variant="determinate"
            value={progress}
            className={progress >= 100 ? classes.hide : ""}
            classes={{
              root: classes.loading,
              colorPrimary: classes.loadingWrap,
              barColorPrimary: classes.bar,
            }}
          />

          <ThemeContext.Provider>{children}</ThemeContext.Provider>
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default ThemeWrapper;
