import React, { useState, useEffect } from "react";
import "./login.css";
import logo from "@assets/images/tn_logo.png";
import LoginForm from "@views/components/auth/login_form";
import { useParams } from "react-router-dom";

export default function SignIn() {
  const { type } = useParams();
  // useEffect(() => {
  //   localStorage.clear();
  // }, []);
  return (
    <div className="login-container">
      <div className="contact-box">
        <div className="left">
          <div className="login-card transparent">
            {/* <img className="login-card-img" src={home} alt="Card Image" /> */}
            <div className="login-overlay">
              <div className="row">
                <div className="icon">
                  <img src={logo} alt="icon" height={80} />
                </div>
                <div className="text-center">TN ePass</div>
                {}
                <div className="text-center">
                  {type !== "outside-india"
                    ? "இந்தியாவுக்குள் இருந்து / Inside India"
                    : "இந்தியாவுக்கு வெளியில் இருந்து / Out Side India"}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="text-center">
            {type !== "outside-india"
              ? "இந்தியாவுக்குள் இருந்து / Inside India"
              : "இந்தியாவுக்கு வெளியில் இருந்து / Out Side India"}{" "}
          </div>
          <h2 style={{ marginTop: "5px" }}>உள்நுழைய / Login</h2>
          <div className="field">
            <LoginForm is_india={type === "outside-india" ? false : true} />
          </div>
        </div>
      </div>
    </div>
  );
}
