import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";

const SubmitButton = (props) => {
  const { show_icon, label, icon_class_name, class_name, loading } = props;

  return (
    <div className={class_name}>
      <LoadingButton
        variant="contained"
        fullWidth
        color="primary"
        size="large"
        type="submit"
        loading={loading}
      >
        {label}
        {show_icon && (
          <ArrowForward className={icon_class_name} disabled={false} />
        )}
      </LoadingButton>
    </div>
  );
};
export default SubmitButton;
