import React, { useEffect, useState } from "react";

import {
  dynamicClear,
  dynamicRequest,
  submit_local_resident_application,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { showToast } from "@helpers/toast";
import Pass from "./local_pass";
import { navigate } from "@helpers/navigator";

function LocalResident(props) {
  const { handle_primary_cancel } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [model_open, set_model_open] = useState(false);
  const data = useDynamicSelector("submit_local_resident_application");

  useEffect(() => {
    if (data?.id) {
      set_model_open(true);
    }
  }, [data]);

  useEffect(() => {
    form.resetFields();
  }, []);

  const handle_cancel = () => {
    set_model_open(false);
    form.resetFields();
    dispatch(dynamicClear("submit_local_resident_application"));
    navigate("/dashboard");
    handle_primary_cancel();
  };
  const on_finish = (values) => {
    const trimmedValue = values.registrationnumber.trim().replace(/ /g, "");

    let submit_application_key = [
      { key: "submit_local_resident_application", loading: true },
    ];
    let submit_application_variables = {
      data: { vehicle_number: trimmedValue },
    };
    dispatch(
      dynamicRequest(
        submit_application_key,
        submit_local_resident_application,
        submit_application_variables,
        "M"
      )
    );
  };

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={on_finish}
        // onValuesChange={onChange}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} md={24} lg={24} className="form-align-end">
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="registrationnumber"
              label="Enter Your Vehicle Registration Number"
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Vehicle Registration Number!",
                },
              ]}
            >
              <Input style={{ textTransform: "uppercase" }} />
            </Form.Item>
          </Col>
        </Row>
        <div>
          <h4 className="text-red text-align-center">
            {data?.error?.message === "not_in_records"
              ? "The vehicle number does not match our records"
              : data?.error?.message === "you_claimed"
              ? 'You already claimed an ePass for the given vehicle number. Please Check the "Previous Passes" section'
              : data?.error?.message === "someone_claimed"
              ? "Someone has already claimed an ePass for the given vehicle number. Please contact your RTO or Local Administration"
              : ""}
          </h4>
        </div>
        <div>
          <span>
            <h6 className="note-word">Note :</h6>
            If your data along with mobile number was already submitted to your
            RTO/District administration, your ePass would have been issued and
            can be found in the "Previous Passes" section for download. If
            available, you need not apply again.
          </span>
          <br />
          <span style={{ fontSize: "14px" }}>
            உங்கள் தகவல்கள் மற்றும் மொபைல் எண் RTO அலுவலகம் / மாவட்ட
            நிர்வாகத்திடம் ஏற்கனவே சமர்ப்பிக்கப்பட்டிருந்தால், உங்கள் ePass
            வழங்கப்பட்டிருக்கும். "முந்தைய பாஸ்கள்" பிரிவில் இருந்து பதிவிறக்கம்
            செய்து கொள்ளலாம். இது கிடைக்கும் பட்சத்தில், மீண்டும் விண்ணப்பிக்க
            வேண்டியதில்லை.
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#346732" }}
              loading={data?.loading}
            >
              Get ePass
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Modal
        title={"Download Your Pass"}
        open={model_open}
        width={1000}
        // onOk={handle_ok}
        onCancel={handle_cancel}
        footer={null}
      >
        <Pass data={data} />
      </Modal>
    </>
  );
}

export default LocalResident;
