import { retrieveItem } from "@helpers/storage";
import {
  cancel_epass_mutation,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { Form, Button, Row, Col, Select, Input, Card } from "antd";
import { capitalize } from "lodash";
import moment from "moment";
import { useDispatch } from "react-redux";

const CancelPass = (props) => {
  const { application_details } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { loading: cancel_application_loading } =
    useDynamicSelector("cancel_application");

  const reason_id = Form.useWatch("cancellation_reason_id", form);
  //   const cancel_reasons = retrieveItem("reasons");

  const on_finish = (values) => {
    let key = [{ key: "cancel_application", loading: true }];
    let query = cancel_epass_mutation;
    let variables = {
      data: {
        application_id: application_details?.id,
        cancellation_reason_id: values?.cancellation_reason_id,
        description: values?.description || "",
      },
    };

    dispatch(dynamicRequest(key, query, variables, "M"));
  };

  const cancel_reasons = [
    {
      id: "3c319124-d51e-480d-ab37-ebdb5322beb9",
      name: "Postponed travel date",
    },
    {
      id: "5292d661-9162-41b1-8657-204877dcd1f0",
      name: "Canceled the travel plan",
    },
    {
      id: "d13abb5d-4761-4ae7-9595-eb16d43f8b3a",
      name: "Applied by mistake/duplicate",
    },
    { id: "23acc23c-515a-4ad4-baf8-9d4a7ce0f8d0", name: "Others" },
  ];
  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={on_finish}
      // onValuesChange={() => {}}
      layout="vertical"
    >
      <Card style={{ marginBottom: "15px" }}>
        <table>
          <tr>
            <th>Pass Number</th>
            <td>{application_details.pass_number}</td>
          </tr>
          <tr>
            <th>Applicant Name</th>
            <td>{application_details.name}</td>
          </tr>
          <tr>
            <th>Coming From</th>
            <td>
              {application_details?.from_district
                ? application_details?.from_district?.name
                : application_details?.from_state
                ? application_details?.from_state?.name
                : application_details?.from_country?.name}
            </td>
          </tr>
          <tr>
            <th>Applied On</th>
            <td>
              {moment(application_details?.applied_on).format("DD/MM/YYYY")}
            </td>
          </tr>

          <tr>
            <th>Valid From</th>
            <td>
              {moment(application_details?.valid_from).format("DD/MM/YYYY")}
            </td>
          </tr>
          <tr>
            <th>Valid Till</th>
            <td>
              {moment(application_details?.valid_till).format("DD/MM/YYYY")}
            </td>
          </tr>
          <tr>
            <th>Destination</th>
            <td>{application_details?.destination?.name}</td>
          </tr>
          <tr>
            <th>Traveler Count</th>
            <td>{application_details.total_passengers}</td>
          </tr>
          <tr>
            <th>Vehicle Type</th>
            <td>{application_details?.vehicle_type?.name}</td>
          </tr>
          <tr>
            <th>Fuel Type</th>
            <td>{capitalize(application_details.vehicle_fuel_type)}</td>
          </tr>
        </table>
      </Card>
      <Row gutter={[16, 0]}>
        <Col xs={24}>
          <Form.Item
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="cancellation_reason_id"
            label="Reason"
            rules={[{ required: true, message: "Please select the reason!" }]}
          >
            <Select size="large">
              {cancel_reasons?.map((reason) => {
                return (
                  <Option key={reason.id} value={reason.id}>
                    {reason?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        {reason_id == "23acc23c-515a-4ad4-baf8-9d4a7ce0f8d0" && (
          <Col xs={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="description"
              label="Please specify"
              rules={[
                {
                  required: true,
                  message: "Please enter the reason!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ backgroundColor: "#346732" }}
            loading={cancel_application_loading}
          >
            Submit
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default CancelPass;
