import lightPalette from "../palette/lightPalette";
import darkPalette from "../palette/darkPalette";

const themePalette = (color, mode) => {
  if (mode === "dark") {
    return darkPalette[color];
  }
  return lightPalette[color];
};

export default themePalette;
